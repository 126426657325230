// assets
import { DashboardOutlined, UserOutlined, LineChartOutlined, RocketOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined,
    UserOutlined,
    LineChartOutlined,
    RocketOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: '/dashboard/customers',
            icon: icons.UserOutlined,
            breadcrumbs: false
        },
        {
            id: 'products',
            title: 'Products',
            type: 'item',
            url: '/dashboard/products',
            icon: icons.RocketOutlined,
            breadcrumbs: false
        },
        {
            id: 'chart',
            title: 'Chart',
            type: 'item',
            url: '/dashboard/chart',
            icon: icons.LineChartOutlined,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
