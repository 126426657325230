// material-ui
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Mantis" width="100" />
         *
         */
        <Typography variant="h1" component="h2" style={{
            fontFamily: theme.typography.h2.fontFamily,
            fontSize: theme.typography.h2.fontSize
        }}>
            BDT
        </Typography>
    );
};

export default Logo;
