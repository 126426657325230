//const URL = "http://api.bdt.test";
const URL = "http://api.kpiweek.info";

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}

function getToken() {
  return new Promise((resolve, reject) => {
    fetch(URL + "/sanctum/csrf-cookie", {
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    }).then(res => resolve(true))
    .catch(err => reject(err));
  });
}

function getTokenTest(){
  return new Promise((resolve, reject) => resolve(true));
}

function getUser() {
  return new Promise((resolve, reject) => {
    fetch(URL + "/api/user", {
      headers: {
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN").slice(0, -3),
        "Accept": "application/json"
      },
      credentials: "include"
    }).then(res_user => {
      switch (res_user.status) {
        case 200:
          res_user.json()
            .then(user => resolve({result: 200, data: user}))
            .catch(err => reject({msg: "Error in retrieving user", err: err}));
          break;
        case 401:
          resolve({data: "Unauthorized", result: 401});
          break;
      }
    }).catch(err => reject({msg: "Error in requesting user", err: err}));
  });
}

function getUserTest(){
  return new Promise((resolve, reject) => resolve({result: 200, data: { 
    "id":1, 
    "name":"User1",
    "email":"user1@domain.com",
    "email_verifed_at":null,
    "created_at":"2022-12-25T15:49:31.000000Z",
    "updated_at":"2022-12-25T15:49:31.000000Z"
 }}));
}

function getProducts() {
  return new Promise((resolve, reject) => {
    fetch(URL + "/api/products", {
      method: "GET",
      headers: {
        'X-XSRF-TOKEN': getCookie("XSRF-TOKEN").slice(0, -3),
        'Accept': 'application/json'
      },
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 200:
          res.json()
            .then(products => resolve({products: products.data}))
            .catch(err => reject({msg: "Error in parsing products list", err: err}));
          break;
        default:
          reject({msg: "Products list return unknown code", err: res.status});
          break;
      }
    })
      .catch(err => reject({msg: "Error in requesting products list", err: err}));
  });
}

function getProductsTest(){
  return new Promise((resolve, reject) => resolve({products: [
    {
        "id":1,
        "name":"Product One",
        "alias": "prod-1",
        "description":"Description of first product",
        "customer_id":2
    },
    {
        "id":2,
        "name":"Product Two",
        "alias": "prod-2",
        "description":null,
        "customer_id":2
    },
    {
        "id":3,
        "name":"Product Three",
        "alias": "prod-3",
        "description":null,
        "customer_id":null
    }
 ]}));
}

function editProduct(product) {
  return new Promise((resolve, reject) => {
    fetch(URL + "/api/products/" + product.id, {
      method: "PUT",
      headers: {
        'X-XSRF-TOKEN': getCookie("XSRF-TOKEN").slice(0, -3),
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        name: product.name,
        alias: product.alias,
        description: product.description,
        customer_id: product.customer_id
      }),
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 200:
          resolve({msg: "Success in product editing"})
          break;
        case 400:
          res.json()
            .then(res => reject({msg: "Error in updating product data", err: res.meta.details}))
            .catch(err => reject({msg: "Error in retrieving update status", err: err}));
          break;
        default:
          reject({msg: "Updating product return unknown code", err: res.status});
          break;
      }
    })
      .catch(err => reject({msg: "Error in updating product", err: err}));
  });
}

function deleteProduct(id) {
  return new Promise((resolve, reject) => {
    fetch(URL + "/api/products/" + id, {
      method: "DELETE",
      headers: {
        'X-XSRF-TOKEN': getCookie("XSRF-TOKEN").slice(0, -3),
        'Accept': 'application/json'
      },
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 200:
          resolve({msg: "Sucess in product deletion"});
          break;
        case 400:
          reject({msg: "Maybe, No such ID"});
          break;
        default:
          reject({msg: "Product deleting return unknown code", err: res.status});
          break;
      }
    })
      .catch(err => reject({msg: "Error in deleting user", err: err}));
  });
}

function deleteProductTest(id){
  return new Promise((resolve) => {
    resolve({msg: "Sucess in product deletion"});
  });
}

const productMiddleware = store => next => action => {
  switch (action.type) {
    case "products/removeProduct":
      deleteProduct(action.payload.id)
        .then(res => next(action))
        .catch(err => console.log("Error in product removing"));
      break;
    case "products/editProduct":
      editProduct(action.payload)
        .then(res => next(action))
        .catch(err => console.log("Error in product editing"));
      break;
    case "customers/removeCustomer":
      deleteCustomer(action.payload.id)
        .then(res => next(action))
        .catch(err => console.log("Error in customer removing"));
      break;
    default:
      return next(action);
  }
};

const productMiddlewareTest = store => next => action => {
  //console.log(action.payload);
  switch (action.type) {
    case "products/removeProduct":
      deleteProductTest(action.payload.id)
        .then(res => next(action))
        .catch(err => console.log("Error in product removing"));
      break;
    case "products/editProduct":
      return next(action);
    case "customers/removeCustomer":
      deleteCustomerTest(action.payload.id)
        .then(res => next(action))
        .catch(err => console.log("Error in customer removing"));
      break;
    default:
      return next(action);
  }
};

function getCustomers() {
  return new Promise((resolve, reject) => {
    fetch(URL + "/api/customers", {
      method: "GET",
      headers: {
        'X-XSRF-TOKEN': getCookie("XSRF-TOKEN").slice(0, -3),
        'Accept': 'application/json'
      },
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 200:
          res.json()
            .then(customers => resolve({customers: customers.data}))
            .catch(err => reject({msg: "Error in parsing customers list", err: err}));
          break;
        default:
          reject({msg: "Customers list return unknown code", err: res.status});
          break;
      }
    })
      .catch(err => reject({msg: "Error in requesting customers list", err: err}));
  });
}

function deleteCustomer(id) {
  return new Promise((resolve, reject) => {
    fetch("http://api.kpiweek.info/api/customers/" + id, {
      method: "DELETE",
      headers: {
        'X-XSRF-TOKEN': getCookie("XSRF-TOKEN").slice(0, -3),
        'Accept': 'application/json'
      },
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 200:
          resolve({msg: "Sucess in user deletion"});
          break;
        case 400:
          reject({msg: "Maybe, No such ID"});
          break;
        default:
          reject({msg: "Customer deleting return unknown code", err: res.status});
          break;
      }
    })
      .catch(err => reject({msg: "Error in deleting user", err: err}));
  });
}

function deleteCustomerTest(id){
  return new Promise((resolve) => {
    resolve({msg: "Sucess in user deletion"});
  });
}

function getCustomersTest() {
  return new Promise((resolve, reject) => resolve({
    customers: [
      {
        "id": 1,
        "name": "Cusomer One",
        "private": 1
      },
      {
        "id": 2,
        "name": "Cusomer Two",
        "private": 0
      }
    ]
  }));
}

function Login(email, password) {
  return new Promise((resolve, reject) => {
    fetch(URL + "/login", {
      method: 'POST',
      headers: {
        'X-XSRF-TOKEN': getCookie("XSRF-TOKEN").slice(0, -3),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password
      }),
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 204:
        case 302:
          resolve({message: "OK"});
          break;
        case 422:
          reject({message: "These credentials do not match our records."})
          break;
      }
    })
      .catch(err => reject({message: err}));
  });
}

function getInfluxData(startTime, stopTime,filter, agwEvery, agwEveryUnit, agwFn) {
  return new Promise((resolve, reject) => {
    fetch(URL + "/api/timeseries/filter-flux-query", {
      headers: {
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN").slice(0, -3),
        "Accept": "application/json",
        'Content-type': 'application/json'
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        range: {
          start: startTime,
          stop: stopTime
        },
        filter: filter,
        agw: {
          every: agwEvery + agwEveryUnit,
          fn: agwFn,
          createEmpty: true
        }
      })
    }).then(res => res.json()).then(res => resolve({msg: "Success", data: res}))
    .catch(err => reject({msg: "Error in querying data", err: err}));
  });
}

function getInfluxDataTest(){
  return new Promise((resolve) => {
    setTimeout(() => resolve({msg: "Test success", data: {data: []}}), 25000);
  });
}

function getTags(){
  return new Promise((resolve, reject) => {
    fetch(URL + '/api/timeseries/tags', {
      method: 'GET',
      headers: {
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN").slice(0, -3),
        "Accept": "application/json"
      },
      credentials: 'include'
    }).then(res => {
      switch(res.status){
        case 200:
          res.json()
            .then(tags => {
              let tags_list = [];
              for (let i=0; i<tags.data.length; i++){
                if (tags.data[i]._value === "_start" || tags.data[i]._value === "_stop") continue;
                tags_list.push({tag: tags.data[i]._value})
              }
              resolve({msg: "Success", tags: tags_list});
            })
            .catch(err => reject({msg: "Error in parsing tags list", err: err}));
          break;
        default:
          reject({msg: "Error in retrieving tags list", err: res.status});
          break;
      }
    })
    .catch(err => reject({msg: "Error in requesting tags list", err: err}));
  });
}

function getTagsTest (){
  return new Promise((resolve, reject) => {
    resolve({
      msg: "Success",
      tags: [
        { tag: "_field"},
        { tag: "_measurement"},
        { tag: "area" },
        { tag: "env" },
        { tag: "equipmentId" },
        { tag: "equipmentSubtype" },
        { tag: "equipmentType" },
        { tag: "location" },
        { tag: "param" },
        { tag: "processCellId" },
        { tag: "processCellType" },
        { tag: "site" },
        { tag: "unitId" },
        { tag: "unitType" }
      ]
    });
  });
}

function getRTData() {
  return new Promise((resolve, reject) => {
    fetch(URL + '/api/timeseries/last', {
      method: 'GET',
      headers: {
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN").slice(0, -3),
        "Accept": "application/json"
      },
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 200:
          res.json()
            .then(values => {
              if (values.data.length > 0){
                resolve({ msg: "Success", data: values.data });
              }
              reject({msg: "Empty data in response"});
            })
            .catch(err => reject({ msg: "Error in parsing rt values", err: err }));
          break;
        default:
          reject({ msg: "Error in retrieving rt values", err: res.status });
          break;
      }
    })
      .catch(err => reject({ msg: "Error in requesting rt values", err: err }));
  });
}

function getRTDataTest() {
  return new Promise((resolve) => {
    resolve({
      data: []
    })
    // resolve({
    //   data: [{
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "value",
    //     param: "temperature",
    //     equipmentType: "sensor",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     env: "water",
    //     location: "input",
    //     unitId: "1"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "value",
    //     param: "temperature",
    //     equipmentType: "sensor",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     env: "water",
    //     location: "output",
    //     unitId: "1"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "value",
    //     param: "temperature",
    //     equipmentType: "sensor",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     env: "water",
    //     location: "input",
    //     unitId: "2"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "value",
    //     param: "temperature",
    //     equipmentType: "sensor",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     env: "water",
    //     location: "output",
    //     unitId: "2"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "value",
    //     param: "temperature",
    //     equipmentType: "sensor",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     env: "smoke",
    //     location: "output",
    //     unitId: "1"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "value",
    //     param: "temperature",
    //     equipmentType: "sensor",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     env: "smoke",
    //     location: "output",
    //     unitId: "2"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "power",
    //     equipmentType: "controls",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     unitId: "1"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: Math.random() * 100,
    //     _field: "power",
    //     equipmentType: "controls",
    //     processCellType: "module",
    //     unitType: "boiler",
    //     unitId: "2"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: 0,
    //     _field: "mode",
    //     equipmentType: "controls",
    //     unitId: "1"
    //   },
    //   {
    //     _time: new Date().toLocaleString(),
    //     _value: 0,
    //     _field: "mode",
    //     equipmentType: "controls",
    //     unitId: "2"
    //   }]
    // })
  });
}

function getTagValues(tag) {
  return new Promise((resolve, reject) => {
    fetch(URL + '/api/timeseries/tags/' + tag, {
      method: 'GET',
      headers: {
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN").slice(0, -3),
        "Accept": "application/json"
      },
      credentials: 'include'
    }).then(res => {
      switch (res.status) {
        case 200:
          res.json()
            .then(values => {
              let list = [];
              for (let i=0; i<values.data.length; i++){
                list.push(values.data[i]._value);
              }
              resolve({msg: "Success", values: list});
            })
            .catch(err => reject({msg: "Error in parsing tag values list", err: err}));
          break;
        default:
          reject({msg: "Error in retrieving tag values list", err: res.status});
          break;
      }
    })
      .catch(err => reject({msg: "Error in requesting tag values list", err: err}));
  });
}

function getTagValuesTest(tag){
  return new Promise((resolve) => resolve({
    msg: "Success",
    values: [tag + " value 1", tag + " value 2", tag + " value 3", tag + " value 4"]
  }));
}

function filterEvents() {
  return new Promise((resolve, reject) => {
    fetch(URL + '/api/events/filter-query', {
      method: 'POST',
      headers: {
        "X-XSRF-TOKEN": getCookie("XSRF-TOKEN").slice(0, -3),
        "Accept": "application/json",
        'Content-type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        collection: "operation",
        limit: 10,
        order_by: {
          key: "created_at",
          sort: "desc"
        }
      })
    }).then(res => {
      switch (res.status) {
        case 200:
          res.json()
            .then(values => {
              resolve({ msg: "Success", events: values.data });
            })
            .catch(err => reject({ msg: "Error in parsing events list", err: err }));
          break;
        default:
          reject({ msg: "Error in retrieving events list", err: res.status });
          break;
      }
    })
      .catch(err => reject({ msg: "Error in requesting events list", err: err }));
  });
}

function filterEventsTest() {
  return new Promise((resolve) => {
    resolve({
      msg: "Success",
      events: {
        meta:
        {
          status: "success"
        },
        data: [
          {
            topic: "Some topic",
            id: "1",
            essence: "boiler",
            event:
            {
              type: "event",
              details:
              {
                description: "Some note",
                place: "Damper",
                state: "On"
              }
            },
            updated_at: "2023-04-23T19:55:25.111000Z",
            created_at: "2023-04-23T19:55:25.111000Z",
            _id: "64458d2d7b9e398f2b00f1e5"
          }
        ]
      }
    });
  });
}

module.exports.getCookie = getCookie;
module.exports.Login = Login;

module.exports.getToken = getToken;
module.exports.getUser = getUser;
module.exports.getProducts = getProducts;
module.exports.getCustomers = getCustomers;
module.exports.getTags = getTags;
module.exports.getTagValues = getTagValues;
module.exports.queryData = getInfluxData;
module.exports.deleteProduct = deleteProduct;
module.exports.productMiddleware = productMiddleware;
module.exports.getRTData = getRTData;
module.exports.filterEvents = filterEvents;

// module.exports.getToken = getTokenTest;
// module.exports.getUser = getUserTest;
// module.exports.getProducts = getProductsTest;
// module.exports.getCustomers = getCustomersTest;
// module.exports.getTags = getTagsTest;
// module.exports.getTagValues = getTagValuesTest;
// module.exports.queryData = getInfluxDataTest;
// module.exports.deleteProduct = deleteProductTest;
// module.exports.productMiddleware = productMiddlewareTest;
// module.exports.getRTData = getRTDataTest;
// module.exports.filterEvents = filterEventsTest;