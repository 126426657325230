import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import ProtectedRoute from '../utils/auxiliary/components/ProtectedRoute';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));
const Products = Loadable(lazy(() => import('../pages/products')));
const Customers = Loadable(lazy(() => import('../pages/customers')));
const Chart = Loadable(lazy(() => import('../pages/chart')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <ProtectedRoute>
        <MainLayout />
    </ProtectedRoute>,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                },
                {
                    path: 'customers',
                    element: <Customers/>
                },
                {
                    path: 'products',
                    element: <Products/>
                },
                {
                    path: 'chart',
                    element: <Chart/>
                }
            ]
        }
    ]
};

export default MainRoutes;
