// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import user from './user';
import products from './products';
import customers from './customers';
import files from './files';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, user, products, customers, files });

export default reducers;
