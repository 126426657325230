// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    user: {
        id: 7654345,
        name: "initial redux user"
    }
};

// ==============================|| SLICE - MENU ||============================== //

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload.user;
        }
    }
});

export default user.reducer;

export const { setUser } = user.actions;
