import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getCustomers, getProducts, getToken, getUser } from '../functions';
import { useDispatch } from 'react-redux';

import { setUser } from '../../../store/reducers/user';
import { setProducts } from '../../../store/reducers/products';
import { setCustomers } from '../../../store/reducers/customers';

function ProtectedRoute({ children }) {
    const [xLoading, setLoading] = useState(true);
    const [xUnauthorized, setUnauthorized] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("Protected route");
        getToken()
            .then(res => {
                getUser()
                    .then(data => {
                        switch (data.result) {
                            case 200:
                                setUnauthorized(false);
                                dispatch(setUser({ user: data.data }));
                                getProducts()
                                    .then(res => {
                                        dispatch(setProducts({ list: res.products }));
                                        getCustomers()
                                            .then(customers => dispatch(setCustomers({ list: customers.customers })))
                                            .catch(err => console.log(err));
                                    })
                                    .catch(err => console.log(err));
                                break;
                            case 401:
                                setUnauthorized(true);
                                break;
                        }
                        setLoading(false);
                    }).catch(err => console.log(err))
            }).catch(err => console.log(err));
    }, []);

    if (xLoading) {
        return <div>Loading....</div>;
    } 
    if (xUnauthorized){
        return <Navigate to="/login"/>;
    }

    return children;
}

export default ProtectedRoute;