// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    list: []
};

// ==============================|| SLICE - MENU ||============================== //

const customers = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setCustomers(state, action) {
            state.list = action.payload.list;
        },
        removeCustomer(state, action){
            console.log('Customer removing....');
            state.list = state.list.filter((customer) => customer.id !== action.payload.id);
        }
    }
});

export default customers.reducer;

export const { setCustomers, removeCustomer } = customers.actions;
