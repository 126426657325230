// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    list: []
};

// ==============================|| SLICE - MENU ||============================== //

const files = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setFiles(state, action) {
            state.list = action.payload.list;
        },
        uploadFile(state, action) {
            console.log("File uploading");
            console.log(state);
            console.log(action);
        },
        removeFile(state, action){
            state.list = state.list.filter((file) => file.id !== action.payload.id);
        }
    }
});

export default files.reducer;

export const { setFiles, uploadFile, removeFile } = files.actions;