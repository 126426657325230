// types
import { createSlice } from '@reduxjs/toolkit';
import { deleteProduct } from '../../utils/auxiliary/functions';

// initial state
const initialState = {
    list: []
};

// ==============================|| SLICE - MENU ||============================== //

const products = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts(state, action) {
            state.list = action.payload.list;
        },
        editProduct(state, action){
            //console.log("Product editing....");
            let products = state.list;
            const index = products.findIndex((product) => product.id === action.payload.id);
            products[index].alias = action.payload.alias;
            products[index].name = action.payload.name;
            products[index].description = action.payload.description;
            products[index].customer_id = action.payload.customer_id;
            state.list = products;            
        },
        removeProduct(state, action){
            //console.log('Product removing....');
            state.list = state.list.filter((product) => product.id !== action.payload.id);
        }
    }
});

export default products.reducer;

export const { setProducts, removeProduct, editProduct } = products.actions;
